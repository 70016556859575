import React, { useState, useEffect } from "react";

import AppLayout from "./layout";
import { useHistory } from "react-router-dom";


import boopSfx from "../sounds/wait.mp3";


export default function Idle() {
  const history = useHistory();

  

  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <img
        id={"my-link"}
        src={process.env.PUBLIC_URL + "/wait2.gif"}
        alt="Open Cabinet"
        style={{ width: "100%", height: "100%" }}
      />
      <audio autoPlay>
        <source src={boopSfx} type="audio/mpeg"/>
      </audio>
    </AppLayout>
  );
}
